import "../styles/globals.css";

import { CookieConsentProvider } from "@use-cookie-consent/react";
import type { AppProps } from "next/app";
import NextNProgress from "nextjs-progressbar";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <CookieConsentProvider>
      <NextNProgress />
      <Component {...pageProps} />
    </CookieConsentProvider>
  );
}

export default MyApp;
